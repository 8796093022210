import * as React from 'react';
import { Link } from 'gatsby';
import { Box, Heading, Paragraph } from 'grommet';

import Layout from '../components/Layout';

// styles
const pageStyles = {
  color: '#232129',
  padding: '96px',
};

const headingStyles = {
  marginTop: 0,
  marginBottom: 64,
};

const paragraphStyles = {
  marginBottom: 48,
};

// markup
const NotFoundPage = () => {
  return (
    <Layout>
      <Box style={pageStyles} fill justify="center" align="center">
        <title>Sidan hittades inte</title>
        <Heading style={headingStyles} level="1">
          Sidan hittades inte
        </Heading>
        <Paragraph style={paragraphStyles}>
          Tyvärr{' '}
          <span role="img" aria-label="Pensive emoji">
            😔
          </span>{' '}
          sidan du försöker nå finns inte.
          <br />
          <br />
          <Link to="/">Tillbaka till hemsidan</Link>.
        </Paragraph>
      </Box>
    </Layout>
  );
};

export default NotFoundPage;
