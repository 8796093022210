import React from 'react';
import Navbar from './Navbar';
import {
  Anchor,
  Box,
  Footer,
  Grommet,
  Main,
  Text,
  ThemeContext,
  ResponsiveContext,
} from 'grommet';
import { theme } from '../themes/custom-theme';
import '../styles/global.css';

export default function Layout({ children, dayHeader }) {
  const [darkMode, setDarkMode] = React.useState(false);

  return (
    <Grommet full theme={theme} themeMode={darkMode ? 'dark' : 'light'}>
      <Box style={{ minHeight: '100vh' }}>
        <Navbar
          darkMode={darkMode}
          setDarkMode={setDarkMode}
          dayNav={dayHeader}
        />
        <Main align="center" justify="center" fill>
          {children}
        </Main>
        <ResponsiveContext.Consumer>
          {(size) => (
            <Footer
              align="center"
              justify="center"
              background="background-footer"
              pad="small"
              direction="column"
              gap="small"
              fill="horizontal">
              <ThemeContext.Extend
                value={{
                  text: {
                    small: {
                      size: '14px',
                    },
                    medium: {
                      size: '18px',
                    },
                    large: {
                      size: '20px',
                    },
                    xlarge: {
                      size: '22px',
                    },
                    xxlarge: {
                      size: '28px',
                    },
                  },
                }}>
                <Anchor
                  color="brand"
                  label="Besök Forsheda Missionförsamlings hemsida"
                  href="https://www.forshedamission.se/"
                  target="_blank"
                  rel="noreferrer"
                  size={size}
                />
              </ThemeContext.Extend>
              <ThemeContext.Extend
                value={{
                  text: {
                    small: {
                      size: '13px',
                    },
                    medium: {
                      size: '16px',
                    },
                    large: {
                      size: '18px',
                    },
                    xlarge: {
                      size: '20px',
                    },
                    xxlarge: {
                      size: '26px',
                    },
                  },
                }}>
                <Text size={size} margin={{bottom: '15px'}}>
                  Copyright © 2023 Forsheda Missionförsamling
                </Text>
              </ThemeContext.Extend>
            </Footer>
          )}
        </ResponsiveContext.Consumer>
      </Box>
    </Grommet>
  );
}
