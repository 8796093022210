import { graphql, useStaticQuery, navigate } from 'gatsby';
import React from 'react';
import {
  Box,
  Button,
  Layer,
  List,
  ResponsiveContext,
  ThemeContext,
  Text,
} from 'grommet';
import * as Icons from 'grommet-icons';
import '../styles/global.css';

export default function DayMenu({ showMenu }) {
  const data = useStaticQuery(graphql`
    query MyQuery {
      allMarkdownRemark {
        nodes {
          frontmatter {
            title
            slug
          }
        }
      }
    }
  `);

  const size = React.useContext(ResponsiveContext);

  const buttonStyle = {
    position: 'fixed',
    top: ['xsmall', 'small'].includes(size) ? '16px' : '36px',
    right: ['xsmall', 'small'].includes(size) ? '36px' : '126px',
  };

  const dayList = [];

  data.allMarkdownRemark.nodes.forEach((node) => {
    dayList.push({
      title: node.frontmatter.title,
      slug: node.frontmatter.slug,
    });
  });

  const dayListSort = (a, b) =>
    a.slug < b.slug ? -1 : a.slug > b.slug ? 1 : 0;

  dayList.sort(dayListSort);

  return (
    <Layer
      full
      responsive
      plain
      position="center"
      animation="fadeIn"
      onEsc={() => showMenu(false)}>
      <Box
        align="center"
        justify="start"
        background="background-back"
        fill
        overflow="auto">
        <Button
          plain
          type="button"
          icon={<Icons.Close />}
          size="large"
          onClick={() => showMenu(false)}
          style={buttonStyle}
        />
        <Box
          align="center"
          justify="center"
          flex="grow"
          pad={{ vertical: 'xlarge' }}>
          <List
            data={dayList.sort(dayListSort)}
            pad={{ vertical: 'large', horizontal: 'xlarge' }}
            onClickItem={({ item }) => {
              showMenu(false);
              navigate(`/dag/${item.slug}`);
            }}>
            {(item) => (
              <ThemeContext.Extend
                value={{
                  text: {
                    xsmall: {
                      size: '18px',
                    },
                    small: {
                      size: '22px',
                    },
                    medium: {
                      size: '26px',
                    },
                    large: {
                      size: '28px',
                    },
                    xlarge: {
                      size: '30px',
                    },
                    xxlarge: {
                      size: '36px',
                    },
                  },
                }}>
                <Text size={size} weight="bold">
                  Dag {item.slug}
                </Text>
              </ThemeContext.Extend>
            )}
          </List>
        </Box>
      </Box>
    </Layer>
  );
}
