import React from 'react';
import { graphql, Link } from 'gatsby';
import { Box, Heading, Text, ThemeContext, ResponsiveContext } from 'grommet';
import * as Icons from 'grommet-icons';

import Layout from '../../components/Layout';
import '../../styles/global.css';

export default function DayTemplate({ data }) {
  const { html } = data.markdownRemark;
  const { title, dayTheme, date, slug } = data.markdownRemark.frontmatter;

  const firstDay = slug === 1;
  const lastDay = slug === 21;

  const headingBoxWidth = (size) => {
    switch (size) {
      case 'xlarge':
        return '1100px';
      default:
        return '900px';
    }
  };

  const textBoxWidth = (size) => {
    switch (size) {
      case 'xlarge':
        return '900px';
      default:
        return '700px';
    }
  };

  return (
    <Layout dayHeader>
      <title>21 Dagar - Dag {slug}</title>
      <ResponsiveContext.Consumer>
        {(size) => (
          <Box
            margin={{ top: '100px', bottom: '50px' }}
            align="center"
            justify="center"
            flex="grow"
            background="background-front"
            fill>
            <Box
              pad={{ vertical: 'large', horizontal: '2rem' }}
              width={headingBoxWidth(size)}
              align="start"
              justify="center"
              flex="grow">
              <ThemeContext.Extend
                value={{
                  heading: {
                    level: {
                      1: {
                        small: {
                          size: '26px',
                          height: '38px',
                          maxWidth: '700px',
                        },
                        medium: {
                          size: '40px',
                          height: '50px',
                          maxWidth: '1200px',
                        },
                        large: {
                          size: '54px',
                          height: '60px',
                          maxWidth: '1968px',
                        },
                        xlarge: {
                          size: '56px',
                          height: '66px',
                          maxWidth: '2736px',
                        },
                      },
                      2: {
                        small: {
                          size: '26px',
                          height: '30px',
                          maxWidth: '720px',
                        },
                        medium: {
                          size: '26px',
                          height: '30px',
                          maxWidth: '1008px',
                        },
                        large: {
                          size: '36px',
                          height: '40px',
                          maxWidth: '1296px',
                        },
                        xlarge: {
                          size: '40px',
                          height: '46px',
                          maxWidth: '1584px',
                        },
                      },
                      3: {
                        small: {
                          size: '20px',
                          height: '30px',
                          maxWidth: '720px',
                        },
                      },
                    },
                  },
                }}>
                <Heading size={size} level="1">
                  {title}
                </Heading>
                <Heading size={size} level="2">
                  {dayTheme}
                </Heading>
                <Text size={size}>{date}</Text>
              </ThemeContext.Extend>
            </Box>
            <Box
              pad={{ horizontal: '2rem', bottom: '50px' }}
              width={textBoxWidth(size)}
              align="center"
              flex="grow"
              justify="center">
              <Text size={size} dangerouslySetInnerHTML={{ __html: html }} />
            </Box>
            {!firstDay && (
              <Link
                to={`/dag/${slug - 1}`}
                className="buttonStyle"
                style={{
                  left: ['xsmall', 'small', 'medium'].includes(size)
                    ? '5%'
                    : '10%',
                  top: ['xsmall', 'small', 'medium'].includes(size)
                    ? '77vh'
                    : '50vh',
                }}>
                <Icons.Previous />
              </Link>
            )}
            {!lastDay && (
              <Link
                to={`/dag/${slug + 1}`}
                className="buttonStyle"
                style={{
                  right: ['xsmall', 'small', 'medium'].includes(size)
                    ? '5%'
                    : '10%',
                  top: ['xsmall', 'small', 'medium'].includes(size)
                    ? '77vh'
                    : '50vh',
                }}>
                <Icons.Next />
              </Link>
            )}
          </Box>
        )}
      </ResponsiveContext.Consumer>
    </Layout>
  );
}

export const pageQuery = graphql`
  query ($id: String!) {
    markdownRemark(id: { eq: $id }) {
      html
      frontmatter {
        date(formatString: "DD MMMM, YYYY", locale: "sv_SE")
        slug
        title
        dayTheme
        verse
      }
    }
  }
`;
