module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-image/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-styled-components/gatsby-browser.js'),
      options: {"plugins":[],"displayName":true,"fileName":true,"minify":true,"namespace":"","transpileTemplateLiterals":true,"topLevelImportPaths":[],"pure":false,"disableVendorPrefixes":false},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"21 Dagar","short_name":"21 Dagar","start_url":"/","background_color":"#8A8A8A","theme_color":"#5D5D5D","theme_color_in_head":false,"display":"standalone","icon":"src/images/icon.png","cache_busting_mode":"none","legacy":true,"crossOrigin":"anonymous","include_favicon":true,"cacheDigest":null},
    }]
