import * as React from 'react';
import { navigate } from 'gatsby';
import { StaticImage } from 'gatsby-plugin-image';
import {
  Box,
  Button,
  Heading,
  Paragraph,
  Text,
  ResponsiveContext,
} from 'grommet';
import * as Icons from 'grommet-icons';

import Layout from '../components/Layout';
import '../styles/global.css';

export default function IndexPage() {
  // const fadeOutOnScroll = () => {
  //   console.log(window.scrollY);
  //   console.log("HEj");
  //   // if (!element) {
  //   //   return;
  //   // }

  //   // var distanceToTop =
  //   //   window.pageYOffset + element.getBoundingClientRect().top;
  //   // var elementHeight = element.offsetHeight;
  //   // var scrollTop = document.documentElement.scrollTop;

  //   // var opacity = 1;

  //   // if (scrollTop > distanceToTop) {
  //   //   opacity = 1 - (scrollTop - distanceToTop) / elementHeight;
  //   // }

  //   // if (opacity >= 0) {
  //   //   element.style.opacity = opacity;
  //   // }
  // };

  // React.useEffect(() => {
  //   window.addEventListener('scroll', fadeOutOnScroll);
  //   return () => {
  //     window.removeEventListener('scroll', fadeOutOnScroll);
  //   };
  // }, []);

  const bannerHeight = (size) => {
    switch (size) {
      case 'xsmall':
        return '500px';
      case 'small':
        return '700px';
      default:
        return '900px';
    }
  };

  const goToCurrentDay = () => {
    const currentDate = new Date();
    const startDate = new Date('2023-03-13');
    const day =
      Math.floor((currentDate - startDate) / (1000 * 60 * 60 * 24)) + 1;
    if (day > 0 && day <= 21) {
      navigate(`/dag/${day}`);
    } else {
      navigate('/dag/1');
    }
  };

  return (
    <Layout>
      <title>21 Dagar</title>
      <ResponsiveContext.Consumer>
        {(size) => (
          <Box
            align="center"
            justify="center"
            direction="column"
            gap="medium"
            pad={{ horizontal: size, top: '50px' }}
            height={bannerHeight(size)}>
            <Heading textAlign="center" level="1" size={size}>
              21 dagar med Andens frukt och gåvor
            </Heading>
            {/* <Heading textAlign="center" level="1" size={size}>
              Daglig Bibelläsning i 21 dagar
            </Heading> */}
            <Button
              label="Läs nu"
              color="accent-4"
              icon={<Icons.Catalog />}
              primary
              type="button"
              onClick={goToCurrentDay}
              // href="#introduction"
            />
            <Box align="center" justify="end" height="150px" gap="none">
              <Text size="large" color="#5D5D5D">
                Inledning
              </Text>
              <Button
                // icon={<Icons.LinkDown size='large'/>}
                icon={<Icons.Down size="large" />}
                type="button"
                href="#introduction"
                style={{ padding: '0px' }}
              />
            </Box>
          </Box>
        )}
      </ResponsiveContext.Consumer>
      <ResponsiveContext.Consumer>
        {(size) => (
          <Box
            align="center"
            justify="center"
            background="background-front"
            fill="horizontal">
            <Box
              align="center"
              justify="center"
              fill="horizontal"
              pad="medium"
              margin={{ top: '60px' }}>
              <Heading
                id="introduction"
                textAlign="center"
                weight="bold"
                level="2">
                Inledning
              </Heading>
            </Box>
            <Box
              margin={{ bottom: 'large' }}
              align="center"
              justify="center"
              pad={{ horizontal: 'large', bottom: 'xlarge' }}
              width="900px">
              <Paragraph fill size={size} textAlign="center">
                Vi kan ibland tycka att det är svårt att leva som kristen. Vi
                önskar att vi skulle vara lite mer lika Jesus och att vi skulle
                veta hur vi ska handla och tänka i olika situationer. Men vi är
                inte ensamma. Gud har sänt oss den Helige Ande som vår hjälpare.
                Han har också gett oss en hel verktygslåda för att kunna utföra
                det uppdrag som vi är kallade till, nämligen att gå ut med
                evangelium och göra folk till lärjungar. När vi lever nära
                honom, låter hans vilja få bli vår vilja och tillåter honom att
                beskära oss, då kan vi också få bära den frukt som tillhör
                Anden. Och det finns ett helt förråd med verktyg i form av
                Andens gåvor som väntar på att bli utplockade och använda i
                Herrens tjänst. Det här ska vi titta närmare på under 21 dagar.
              </Paragraph>
            </Box>
          </Box>
        )}
      </ResponsiveContext.Consumer>

      <StaticImage
        className="image-banner"
        src="../images/book.jpg"
        alt="site banner"
        layout="fullWidth"
        placeholder="blurred"
        quality="100"
        style={{
          filter: 'opacity(65%)',
          height: '100%',
          width: '100%',
          position: 'absolute',
          left: 0,
          top: 0,
          zIndex: -1,
        }}
      />
    </Layout>
  );
}
