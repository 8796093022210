export const theme = {
  global: {
    breakpoints: {
      // xsmall: {
      //   value: 375,
      // },
      small: {
        value: 600,
        edgeSize: {
          none: '0px',
          small: '14px',
          medium: '12px',
          large: '24px',
        },
      },
      medium: {
        value: 940,
        edgeSize: {
          none: '0px',
          small: '12px',
          medium: '24px',
          large: '48px',
        },
      },
      large: {
        value: 1200,
        edgeSize: {
          none: '0px',
          small: '12px',
          medium: '24px',
          large: '48px',
        },
      },
      xlarge: {
        value: 1800,
        edgeSize: {
          none: '0px',
          small: '12px',
          medium: '24px',
          large: '48px',
          xlarge: '60px',
        },
      },
    },
    colors: {
      brand: {
        dark: '#FFFFFF',
        light: '#5D5D5D',
      },
      background: {
        dark: '#',
        light: '#',
      },
      'background-back': {
        dark: '#111111',
        light: '#E5EBDC',
      },
      'background-front': {
        dark: '#222222',
        light: '#FFFFFF',
      },
      'background-contrast': {
        dark: '#FFFFFF11',
        light: '#11111111',
      },
      'background-footer': {
        dark: '#FFFFFF',
        light: '#767A6F',
      },
      text: {
        dark: '#EEEEEE',
        light: '#5D5D5D',
      },
      'text-strong': {
        dark: '#FFFFFF',
        light: '#343434',
      },
      'text-weak': {
        dark: '#CCCCCC',
        light: '#737373',
      },
      'text-xweak': {
        dark: '#999999',
        light: '#969696',
      },
      border: {
        dark: '#444444',
        light: '#CCCCCC',
      },
      control: 'brand',
      'active-background': 'background-contrast',
      'active-text': 'text-strong',
      'selected-background': 'brand',
      'selected-text': 'text-strong',
      'status-critical': '#FF4040',
      'status-warning': '#FFAA15',
      'status-ok': '#00C781',
      'status-unknown': '#CCCCCC',
      'status-disabled': '#CCCCCC',
      focus: '#00000000',
    },
    font: {
      family: 'Mulish',
      face: `
        /* cyrillic-ext */
        @font-face {
          font-family: 'Mulish';
          font-style: normal;
          font-weight: 400;
          src: url(https://fonts.gstatic.com/s/mulish/v10/1Ptyg83HX_SGhgqO0yLcmjzUAuWexZNR8aqvG4w-.woff2) format('woff2');
          unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
        }
        /* cyrillic */
        @font-face {
          font-family: 'Mulish';
          font-style: normal;
          font-weight: 400;
          src: url(https://fonts.gstatic.com/s/mulish/v10/1Ptyg83HX_SGhgqO0yLcmjzUAuWexZNR8aOvG4w-.woff2) format('woff2');
          unicode-range: U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
        }
        /* vietnamese */
        @font-face {
          font-family: 'Mulish';
          font-style: normal;
          font-weight: 400;
          src: url(https://fonts.gstatic.com/s/mulish/v10/1Ptyg83HX_SGhgqO0yLcmjzUAuWexZNR8aivG4w-.woff2) format('woff2');
          unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+1EA0-1EF9, U+20AB;
        }
        /* latin-ext */
        @font-face {
          font-family: 'Mulish';
          font-style: normal;
          font-weight: 400;
          src: url(https://fonts.gstatic.com/s/mulish/v10/1Ptyg83HX_SGhgqO0yLcmjzUAuWexZNR8amvG4w-.woff2) format('woff2');
          unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
        }
        /* latin */
        @font-face {
          font-family: 'Mulish';
          font-style: normal;
          font-weight: 400;
          src: url(https://fonts.gstatic.com/s/mulish/v10/1Ptyg83HX_SGhgqO0yLcmjzUAuWexZNR8aevGw.woff2) format('woff2');
          unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
        }

        /* cyrillic-ext */
        @font-face {
          font-family: 'Philosopher';
          font-style: normal;
          font-weight: 400;
          src: url(https://fonts.gstatic.com/s/philosopher/v17/vEFV2_5QCwIS4_Dhez5jcWBjT00k0w.woff2) format('woff2');
          unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
        }
        /* cyrillic */
        @font-face {
          font-family: 'Philosopher';
          font-style: normal;
          font-weight: 400;
          src: url(https://fonts.gstatic.com/s/philosopher/v17/vEFV2_5QCwIS4_Dhez5jcWBqT00k0w.woff2) format('woff2');
          unicode-range: U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
        }
        /* vietnamese */
        @font-face {
          font-family: 'Philosopher';
          font-style: normal;
          font-weight: 400;
          src: url(https://fonts.gstatic.com/s/philosopher/v17/vEFV2_5QCwIS4_Dhez5jcWBhT00k0w.woff2) format('woff2');
          unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+1EA0-1EF9, U+20AB;
        }
        /* latin */
        @font-face {
          font-family: 'Philosopher';
          font-style: normal;
          font-weight: 400;
          src: url(https://fonts.gstatic.com/s/philosopher/v17/vEFV2_5QCwIS4_Dhez5jcWBuT00.woff2) format('woff2');
          unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
        }
      `,
    },
    active: {
      background: 'active-background',
      color: 'active-text',
    },
    hover: {
      background: 'active-background',
      color: 'active-text',
    },
    selected: {
      background: 'selected-background',
      color: 'selected-text',
    },
  },
  list: {
    item: {
      border: {
        color: 'text-xweak',
        side: 'horizontal',
        size: 'xsmall',
      },
    },
  },
  anchor: {
    textDecoration: 'none',
    hover: {
      // textDecoration: 'none',
      extend: { color: '#969696' },
    },
  },
  layer: {
    background: {
      dark: '#111111',
      light: '#FFFFFF',
    },
  },
  heading: {
    font: {
      family: 'Philosopher',
    },
    color: {
      dark: '#EEEEEE',
      light: '#5D5D5D',
    },
    level: {
      1: {
        small: {
          size: '49px',
          height: '42px',
          maxWidth: '816px',
        },
        medium: {
          size: '50px',
          height: '56px',
          maxWidth: '1200px',
        },
        large: {
          size: '70px',
          height: '88px',
          maxWidth: '1968px',
        },
        xlarge: {
          size: '80px',
          height: '90px',
          maxWidth: '2736px',
        },
      },
      2: {
        small: {
          size: '30px',
          height: '36px',
          maxWidth: '720px',
        },
        medium: {
          size: '42px',
          height: '48px',
          maxWidth: '1008px',
        },
        large: {
          size: '54px',
          height: '60px',
          maxWidth: '1296px',
        },
        xlarge: {
          size: '60px',
          height: '72px',
          maxWidth: '1584px',
        },
      },
    },
  },
  text: {
    font: {
      family: 'Mulish',
    },
    xsmall: {
      size: '14px',
      height: '20px',
      maxWidth: '336px',
    },
    small: {
      size: '16px',
      height: '22px',
      maxWidth: '384px',
    },
    medium: {
      size: '18px',
      height: '24px',
      maxWidth: '432px',
    },
    large: {
      size: '20px',
      height: '28px',
      maxWidth: '528px',
    },
    xlarge: {
      size: '22px',
      height: '30px',
      maxWidth: '624px',
    },
  },
  paragraph: {
    font: {
      family: 'Mulish',
    },
    xsmall: {
      size: '16px',
      height: '20px',
      maxWidth: '336px',
    },
    small: {
      size: '18px',
      height: '22px',
      maxWidth: '384px',
    },
    medium: {
      size: '22px',
      height: '24px',
      maxWidth: '432px',
    },
    large: {
      size: '24px',
      height: '28px',
      maxWidth: '528px',
    },
    xlarge: {
      size: '28px',
      height: '32px',
      maxWidth: '624px',
    },
  },
};
