import { graphql, useStaticQuery, Link } from 'gatsby';
import React from 'react';
import { Box, Button, Header, Heading, Nav, ResponsiveContext } from 'grommet';
import * as Icons from 'grommet-icons';
import DayMenu from './DayMenu';
import '../styles/global.css';

export default function Navbar({ darkMode, setDarkMode, dayNav }) {
  const data = useStaticQuery(graphql`
    query SiteInfo {
      site {
        siteMetadata {
          title
        }
      }
    }
  `);

  const { title } = data.site.siteMetadata;

  const [dayMenu, setDayMenu] = React.useState();

  const headerStyle = dayNav
    ? {
        position: 'absolute',
        top: 0,
        left: 0,
        boxShadow: '0px 1px 3px #C5C5C5',
        background: 'white',
      }
    : {
        position: 'absolute',
        top: 0,
        left: 0,
      };

  return (
    <ResponsiveContext.Consumer>
      {(size) => (
        <Box fill="horizontal">
          <Header
            align="center"
            direction="row"
            justify="between"
            gap="medium"
            fill="horizontal"
            pad="medium"
            style={headerStyle}>
            <Link to={`/`}>
              <Heading
                margin={{ left: 'large' }}
                color="brand"
                size={size === 'small' ? 'medium' : 'xsmall'}
                level="2">
                {title}
              </Heading>
            </Link>
            <Nav align="center" direction="row-responsive" justify="end">
              <Box
                align="center"
                justify="between"
                direction="row"
                margin={{ right: 'large' }}>
                {/* {darkMode && (
              <Button
                icon={<Icons.Sun />}
                type="button"
                onClick={() => setDarkMode(false)}
                />
                )}
                {!darkMode && (
                  <Button
                  icon={<Icons.Moon />}
                  type="button"
                  onClick={() => setDarkMode(true)}
                  />
                )} */}
                <Button
                  label={['xsmall', 'small'].includes(size) ? '' : 'Meny'}
                  icon={<Icons.Menu />}
                  type="button"
                  plain
                  onClick={() => setDayMenu(true)}
                />
                {dayMenu && <DayMenu showMenu={setDayMenu} />}
              </Box>
            </Nav>
          </Header>
        </Box>
      )}
    </ResponsiveContext.Consumer>
  );
}
